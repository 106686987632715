/* body { */
  /* background-color: #361355 !important; */
  /* background: linear-gradient(45deg, #361355, #684187); */
/* } */

/* body::before {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 700ms;
  transition-timing-function: linear;
  content: "";
  opacity: .75;
  background: radial-gradient(19.49% 58.52% at 50% 0%, rgba(249, 196, 60, 0.3) 0%, rgba(231, 166, 1, 0) 100%),radial-gradient(50% 50% at 100% 100%, #520aeb 10.27%, rgba(159, 115, 255, 0) 100%),radial-gradient(77.86% 77.86% at 100% 0%, #00a7ff 0%, rgba(0, 166, 254, 0.12) 100%),radial-gradient(50% 50% at 0% 0%, #ff8d6c 0%, rgba(255, 92, 0, 0.6) 100%),radial-gradient(70.24% 70.24% at 0% 100%, #e81d72 0%, rgba(255, 0, 0, 0) 100%);
  background-blend-mode: overlay,normal,normal,color-dodge,normal;
  mix-blend-mode: screen;
  -webkit-filter: blur(150px);
  filter: blur(150px);
  position: absolute;
  left: 50%;
  width: 40vw;
  height: 40vh;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  will-change: opacity,width,height;
} */

#menu {
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(30px);
  height: 64px;
  align-items: center;
  /* width: 100%; */
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: 0 0 36px 4px #0000003d;
}

.MuiRadio-action {
  background: transparent !important;
}

.modal-opener::-webkit-scrollbar {
  opacity: 0;
  width: 0px;
}

.modal-opener::-webkit-scrollbar-thumb {
  width: 0px;
}

.hide-scroll::-webkit-scrollbar {
  opacity: 0;
  width: 0px;
}

.hide-scroll::-webkit-scrollbar-thumb {
  width: 0px;
}

.ant-notification-notice-btn {
  float: none !important;
}

.ant-notification-notice-close {
  display: none !important;
}

.ant-notification-notice-description {
  font-size: 12px !important;
}

.cat-card {
  transform: scale(1);
  transition: all 0.2s ease;
}

.cat-card.open {
  transform: scale(1.1);
}